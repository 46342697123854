<template>
  <v-app>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="publishers"
        :page.sync="page"
        :server-items-length="meta.total"
        :items-per-page="meta.per_page"
        :loading="loading"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <state-indicate ref="indicate" v-slot:default="{isLoading}">
            <v-toolbar
              flat
            >
              <v-toolbar-title>Publishers List</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="editedIndex = -1"
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Publisher
                  </v-btn>
                </template>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"

                          >
                            <ValidationProvider name="Name" vid="name" rules="required|max:255" v-slot="{ errors, valid }">
                              <v-text-field
                                :counter="255"
                                v-model="editedItem.name"
                                :error-messages="errors"
                                :success="valid"
                                label="Name"
                              ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Name" vid="name" rules="required|email|max:255" v-slot="{ errors, valid }">
                              <v-text-field
                                :counter="255"
                                v-model="editedItem.email"
                                :error-messages="errors"
                                :success="valid"
                                label="Email"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                        :disabled="isLoading"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                        :disabled="invalid"
                        :loading="isLoading"
                      >
                        {{ editedIndex > -1 ? 'Save' : 'Create'}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </ValidationObserver>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" :disabled="isLoading" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" :loading="isLoading" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </state-indicate>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </v-container>
  </v-app>
</template>

<script>
    import Publisher from '@/models/Publisher';
    import PublishersApi from '@/api/publishers';
    import StateIndicate from "../../../components/utils/StateIndicate";
    import {mapActions} from 'vuex';

    export default {
        name: "Index",
        data() {
            return {
                loading: false,
                publishers: [],
                users: [],
                dialog: false,
                dialogDelete: false,
                editedIndex: -1,
                editedItem: {
                    id: '',
                    password: '',
                    name: ''
                },
                defaultItem: {
                    email: '',
                    password: '',
                    name: ''
                },
                headers: [
                    {text: 'Publisher', value: 'name'},
                    {text: 'Email', value: 'email'},
                    {text: 'Created at', value: 'created_at'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                meta: {},
                page: 1,
            }
        },

        watch: {
            async page() {
                this.fetchPublishers()
            },
            dialog(val) {
                if (val && this.editedIndex === -1) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                }

                if (!val) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1;
                    this.$nextTick(() => {
                        this.$refs.observer.reset()
                    });

                }
            }
        },

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Publisher' : 'Edit Publisher'
            },
        },
        async mounted() {
            this.fetchPublishers()
        },

        methods: {
            ...mapActions('auth', ['registration']),
            fetchPublishers() {
                this.loading = true;
                return Publisher.page(this.page).get().then(ApiResponse => {
                    this.publishers = ApiResponse.getData();
                    this.meta = ApiResponse.getMeta();
                    this.loading = false;
                });
            },
            editItem(item) {
                this.editedIndex = this.publishers.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.publishers.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            async deleteItemConfirm() {
                this.$refs.indicate.isLoading = true
                try {
                    await PublishersApi.delete(this.editedItem.id)
                    this.closeDelete()
                    this.fetchPublishers()
                } catch (e) {}
                this.$refs.indicate.isLoading = false
            },

            close() {
                this.dialog = false
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    Object.assign(this.editedItem, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            async save() {
                if (await this.$refs.observer.validate()) {
                    this.$refs.indicate.isLoading = true;
                    if (this.editedIndex > -1) {
                        try {
                            await PublishersApi.update(this.editedItem.id, this.editedItem);
                            Object.assign(this.publishers[this.editedIndex], this.editedItem);
                            this.close();
                        } catch (ApiResponse) {
                            if (ApiResponse.hasValidationErrors()) {
                                this.$refs.observer.setErrors(ApiResponse.getValidationErrors());
                            } else {
                                alert('Error while for updating Publisher');
                            }
                        }
                    } else {
                        try {
                            await PublishersApi.store(this.editedItem);
                            this.close();
                            this.fetchPublishers()
                        } catch (ApiResponse) {
                            if (ApiResponse.hasValidationErrors()) {
                                this.$refs.observer.setErrors(ApiResponse.getValidationErrors());
                            } else {
                                alert('Error while for creating Publisher');
                            }
                        }
                    }
                    this.$refs.indicate.isLoading = false;
                }
            },
        },

        components: {
            StateIndicate
        }
    }
</script>

<style scoped>

</style>
